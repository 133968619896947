/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it


console.log("");
console.log("%c Coded by Marian Carnoky ", "background: linear-gradient(to right, #da4453, #89216b); padding:5px; font-size: 10px; color: #ffffff");
console.log("%c > LinkedIn: https://www.linkedin.com/in/marián-čarnoký-347400105", "background: linear-gradient(to right, #da4453, #89216b); padding:5px; font-size: 10px; color: #ffffff");
console.log("%c Designed by Platform", "background: linear-gradient(to right, #da4453, #89216b); padding:5px; font-size: 10px; color: #ffffff");
console.log("%c > Site: https://plat4m.com/", "background: linear-gradient(to right, #da4453, #89216b); padding:5px; font-size: 10px; color: #ffffff");
console.log("")
